html {
  height: 100%;
}
body {
  margin:0;
  padding:0;
  /*font-family: sans-serif;*/
  /*background: linear-gradient(#141e30, #243b55);*/
} 

.pit {
  color: #3B97D3;
}

.first-user-install-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  /* height: 450px; */
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.5);
  box-sizing: border-box;
  box-shadow: 0 5px 10px #3B97D3;
  border-radius: 10px;
  border: 1.5px ridge #fff;
}

.first-user-install-dialog h2 {
  margin: 0 0 35px;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 15px;
}

.first-user-install-dialog .user-box {
  position: relative;
}

.first-user-install-dialog .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.first-user-install-dialog .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.first-user-install-dialog .user-box input:focus ~ label,
.first-user-install-dialog .user-box input:valid ~ label {
  top: -30px;
  left: 0;
  color: #66AAD7;
  font-size: 12px;
}

.first-user-install-dialog .user-box select {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.first-user-install-dialog .user-box select option {
  color: #fff; /* Set text color for options */
  background: #000; /* Set background color for options */
}

.first-user-install-dialog .user-box label {
  position: absolute;
  /* top: 10px; */
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.first-user-install-dialog .user-box select:focus ~ label,
.first-user-install-dialog .user-box select:valid ~ label {
  top: -30px;
  left: 0;
  color: #66AAD7;
  font-size: 12px;
}

.first-user-install-dialog .user-box-new label {
  font-size: 16px;
  color: #fff;
}

/* Apply styles to the label and checkbox */
.first-user-install-dialog .user-box-new label {
  display: block; /* Make the label a block element for better spacing */
  color: #fff; /* Set text color for the label */
  margin-bottom: 15px; /* Add some bottom margin for spacing */
}

.first-user-install-dialog .user-box-new label input[type="checkbox"] {
  margin-right: 10px; /* Add right margin to separate the checkbox from the label text */
}

/* Style the checkbox appearance */
.first-user-install-dialog .user-box-new label input[type="checkbox"] {
  width: 15px; /* Set width */
  height: 15px; /* Set height */
  background-color: transparent; /* Make background transparent */
  border: 2px solid #fff; /* Add border */
  border-radius: 4px; /* Add border radius */
  outline: none; /* Remove outline */
  cursor: pointer; /* Change cursor to pointer on hover */
  margin-left: 2px;
  margin-bottom: 10px;
}


/* Style the checkbox when checked */
.first-user-install-dialog .user-box-new label input[type="checkbox"]:checked {
  background-color: #66AAD7; /* Change background color when checked */
}

/* Add hover effect to the checkbox */
.first-user-install-dialog .user-box-new label input[type="checkbox"]:hover {
  border-color: #66AAD7; /* Change border color on hover */
}

.first-user-install-dialog button {
  margin-right: 50px; /* Add right margin to create space between buttons */
}
 
.first-user-install-dialog form button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #66AAD7;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 10px;
  letter-spacing: 4px;
  background: transparent; 
  border: none; 
}

.first-user-install-dialog button:hover {
  background: #66AAD7;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #66AAD7,
              0 0 25px #66AAD7,
              0 0 50px #66AAD7,
              0 0 100px #66AAD7;
}

.first-user-install-dialog button span {
  position: absolute;
  display: block;
}

.first-user-install-dialog button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #66AAD7);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.first-user-install-dialog button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #66AAD7);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.first-user-install-dialog button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #66AAD7);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.first-user-install-dialog button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #66AAD7);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}